'use client';
import { SwiperSlide } from 'swiper/react';
import { IModuleCarouselSlide } from '@/src/interfaces/generated/contentful';
import { MastheadSlides } from '@/src/packages/components/ui/Masthead';
import { useWindowSize } from 'react-use';
import { useEffect, useState } from 'react';
import { screens } from '@/src/packages/utils/generateImageSizes';
import { Box } from '@/src/packages/components/ui/Box';
import Image from 'next/image';
import { Heading } from '@/src/packages/components/ui/Heading';
import { Paragraph } from '@/src/packages/components/ui/Paragraph';
import { Button } from '@/src/packages/components/ui/Button';
import Link from 'next/link';
import { getRoute } from '@/src/packages/utils/routes';
import { paths } from '@/src/packages/routes';
import { pathParams } from '@/src/packages/routes/pathParams.enum';
import { Stack } from '@/src/packages/components/ui/Stack';

export const HomepageMasthead = ({
  slides,
}: {
  slides: IModuleCarouselSlide[];
}) => {
  const { width } = useWindowSize();

  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  return (
    <Box className="-mx-gutter lg:mx-0">
      <MastheadSlides>
        {slides.map((slide, i) => {
          const imageUrl = !isClient
            ? slide.fields.image?.fields.file.url
            : width < screens.lg
              ? slide.fields.imageMobile.fields.file.url
              : slide.fields.image?.fields.file.url;

          return (
            <SwiperSlide key={i}>
              <Box className="grid grid-cols-12">
                <Box
                  position="relative"
                  className="aspect-[4/3] lg:aspect-square"
                  colSpan={{ initial: 12, lg: 6 }}
                >
                  <Image
                    src={imageUrl || ''}
                    fill
                    priority
                    alt=""
                    className="object-cover"
                    sizes="(max-width: 1024px) 100vw, 50vw"
                  />
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  className="px-6 pb-16 pt-8"
                  colSpan={{ initial: 12, lg: 6 }}
                >
                  <Stack className="w-full max-w-lg lg:mx-auto" spaceY={6}>
                    <Stack spaceY={3}>
                      <Heading as="h1" className="font-serif text-3xl">
                        The Dog Accessory Range
                      </Heading>
                      <Paragraph>
                        Our best-selling leather dog accessory set, consisting
                        of a leather leash, harness, collar and poo bag holder,
                        that all work seamlessly together.
                      </Paragraph>
                    </Stack>

                    <Button size="lg" asChild isBlock>
                      <Link
                        href={getRoute({
                          route: paths.RANGES_SINGLE,
                          params: { [pathParams.RANGE_SINGLE]: 'dog' },
                        })}
                      >
                        Shop the range
                      </Link>
                    </Button>
                  </Stack>
                </Box>
              </Box>
            </SwiperSlide>
          );
        })}
      </MastheadSlides>
    </Box>
  );
};
