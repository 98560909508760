import { Box } from '@/src/packages/components/ui/Box';
import { ProductCardContainer } from '@/src/packages/components/ui/ProductCard/ProductCardContainer';
import { paths } from '@/src/packages/routes';
import { pathParams } from '@/src/packages/routes/pathParams.enum';
import { getRoute } from '@/src/packages/utils/routes';
import Image from 'next/image';
import Link from 'next/link';
import { IFetchBundlesSchema } from '@bitmap/contracts';
import { BundlePrice } from '@/src/packages/components/pages/Bundle/BundleHeader';
import { Button } from '@/src/packages/components/ui/Button';

export const BundleCard = ({ bundle }: { bundle: IFetchBundlesSchema[0] }) => {
  const { priceList } = bundle;

  return (
    <Link
      key={bundle.id}
      className="group col-span-12 block lg:col-span-6"
      href={getRoute({
        route: paths.BUNDLES_SINGLE,
        params: { [pathParams.BUNDLE_SINGLE]: bundle.slug },
      })}
    >
      <ProductCardContainer>
        <Box className="relative aspect-square">
          <Image
            src={bundle.images[0].fields.file.url}
            fill
            alt=""
            className="object-cover"
          />
        </Box>

        <Box className="">
          <Box className="p-4 pb-10">
            <Box>{bundle?.name}</Box>
            <Box>
              <BundlePrice priceLists={priceList} />
            </Box>
          </Box>
          <Button
            isBlock
            variant="solid"
            size="lg"
            className="lg:opacity-0 lg:group-hover:opacity-100"
          >
            Select options
          </Button>
        </Box>
      </ProductCardContainer>
    </Link>
  );
};
