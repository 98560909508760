'use client';
import { Grid } from '@/src/packages/components/ui/GridBox';
import { IFetchBundlesSchema } from '@bitmap/contracts';
import { useWindowSize } from 'react-use';
import { Carousel } from '@/src/packages/components/ui/Carousel';
import { SwiperSlide } from 'swiper/react';
import { screens } from '@/src/packages/utils/generateImageSizes';
import { useEffect, useState } from 'react';
import { BundleCard } from '@/src/packages/components/pages/Range/RangeBundleCard';

export const RangeBundles = ({ bundles }: { bundles: IFetchBundlesSchema }) => {
  const { width } = useWindowSize();

  const [isMounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  if (!isMounted) return <></>;

  return (
    <>
      {width < screens.lg ? (
        <Carousel slidesPerView={1.2} isFlush showArrows={false}>
          {bundles.map((bundle) => {
            return (
              <SwiperSlide key={bundle.id}>
                <BundleCard bundle={bundle} />
              </SwiperSlide>
            );
          })}
        </Carousel>
      ) : (
        <Grid>
          {bundles.map((bundle) => {
            return <BundleCard bundle={bundle} key={bundle.id} />;
          })}
        </Grid>
      )}
    </>
  );
};
