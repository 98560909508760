import { Box } from '@/src/packages/components/ui/Box';
import { ReactNode } from 'react';

export const ProductCardContainer = ({ children }: { children: ReactNode }) => {
  return (
    <Box className="bg-background-secondary relative block transition-all duration-200">
      {children}
    </Box>
  );
};
