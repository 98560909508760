'use client';
import { Swiper, useSwiper } from 'swiper/react';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { Box } from '@/src/packages/components/ui/Box';
import type SwiperCore from 'swiper';
import { CarouselArrow } from '@/src/packages/components/ui/CarouselArrow';
import { SwiperOptions } from 'swiper/types';
import clsx from 'clsx';
import { cn } from '@/src/packages/utils/cn';

export const useSwiperReactive = () => {
  const swiper = useSwiper();

  const [, setSignal] = useState({});

  const forceRerender = () => setSignal({});

  useEffect(() => {
    if (swiper) {
      swiper.on('slideChange', forceRerender);
      swiper.on('activeIndexChange', forceRerender);
      swiper.on('init', forceRerender);
    }
  }, []);

  return swiper;
};

export const Carousel = (props: {
  children: ReactNode;
  isFlush?: boolean;
  showArrows?: boolean;
  leftFlush?: boolean;
  slidesPerView?: number;
  breakpoints?: SwiperOptions['breakpoints'];
}) => {
  const {
    children,
    isFlush,
    leftFlush,
    slidesPerView,
    breakpoints,
    showArrows = true,
  } = props;

  const swiperRef = useRef<SwiperCore>();

  const [isEnd, setEnd] = useState(false);

  const [isBeginning, setBeginning] = useState(true);

  const [totalSlides, setTotalSlides] = useState(0);

  return (
    <Box className={cn('group relative', { '-mx-gutter lg:mx-0': isFlush })}>
      <Swiper
        onInit={(swiper) => {
          swiperRef.current = swiper;
          setTotalSlides(swiperRef?.current?.slides?.length);
        }}
        onSlideChange={(swiper) => {
          setBeginning(swiper.isBeginning);
          setEnd(swiper.isEnd);
        }}
        onResize={(swiper) => {
          setBeginning(swiper.isBeginning);
          setEnd(swiper.isEnd);
        }}
        onReachEnd={() => {
          setEnd(true);
        }}
        onReachBeginning={() => {
          setBeginning(true);
        }}
        spaceBetween={2}
        slidesPerView={slidesPerView}
        className={cn({
          '!px-0': isFlush && !leftFlush,
          '!lg:px-0': leftFlush,
        })}
        style={{
          paddingBottom: '2px',
          paddingLeft: 'var(--gutter)',
          paddingRight: 'var(--gutter)',
        }}
        breakpoints={breakpoints}
      >
        {children}
      </Swiper>

      {showArrows && (
        <>
          {!isEnd && (
            <CarouselArrow
              onClick={() => swiperRef?.current?.slideNext()}
              direction="next"
            />
          )}

          {!isBeginning && (
            <CarouselArrow
              onClick={() => swiperRef?.current?.slidePrev()}
              direction="prev"
            />
          )}
        </>
      )}
    </Box>
  );
};
