'use client';
import { Box } from '@/src/packages/components/ui/Box';
import { Button } from '@/src/packages/components/ui/Button';
import { PageSection } from '@/src/packages/components/ui/PageSection';
import { PageSectionTitle } from '@/src/packages/components/ui/PageSection/PageSectionTitle';
import { Paragraph } from '@/src/packages/components/ui/Paragraph';
import { VideoManufacturing } from '@/src/packages/components/ui/Video';
import { paths } from '@/src/packages/routes';
import { pathParams } from '@/src/packages/routes/pathParams.enum';
import { getRoute } from '@/src/packages/utils/routes';
import Link from 'next/link';
import Balancer from 'react-wrap-balancer';

const HomepageMadeInBritain = () => {
  return (
    <PageSection id="made-in-britain">
      <Box className="text-center space-y-4" marginBottom={12}>
        <Box>
          <PageSectionTitle marginBottom={2}>
            Handmade in England
          </PageSectionTitle>
          <Paragraph className="max-w-xl mx-auto text-center">
            <Balancer>
              All our leather goods are proudly handmade by skilled craftsmen
              and women, right here in England.
            </Balancer>
          </Paragraph>
        </Box>
        <Button asChild>
          <Link
            href={getRoute({
              route: paths.MANUFACTURING_SINGLE,
              params: { [pathParams.MANUFACTURING_SINGLE]: 'leather-goods' },
            })}
          >
            Learn more
          </Link>
        </Button>
      </Box>
      <Box className="-mx-gutter lg:mx-0">
        <VideoManufacturing />
      </Box>
    </PageSection>
  );
};

export { HomepageMadeInBritain };
