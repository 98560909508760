import Image from 'next/image';
import { Box } from '../Box';

export const MastheadBackgroundImage = ({
  imageSrc,
}: {
  imageSrc: string | undefined;
}) => {
  return (
    <Box
      className="relative lg:absolute lg:top-0 lg:left-0 lg:z-0 aspect-video"
      width="full"
      height="full"
    >
      {imageSrc && (
        <Image
          src={imageSrc}
          fill
          alt=""
          className="object-cover"
          priority
          sizes="100vw"
        />
      )}
    </Box>
  );
};
