import { ReactNode } from 'react';
import { tv, VariantProps } from 'tailwind-variants';
import clsx from 'clsx';
import { Box } from '../Box';

const useMasthead = tv({
  variants: {
    size: {
      full: 'lg:h-[calc(75vh-var(--navigation-height)-var(--navigation-announcement-height))]',
      small: 'h-[40vh]',
    },
  },
  defaultVariants: {
    size: 'full',
  },
});

type IMastheadProps = {
  children: ReactNode;
} & VariantProps<typeof useMasthead>;

export const Masthead = (props: IMastheadProps) => {
  const { children, size = 'full' } = props;

  return (
    <Box
      className={clsx('relative flex overflow-hidden', useMasthead({ size }))}
    >
      {children}
    </Box>
  );
};
